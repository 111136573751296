/* ./src/index.css or ./src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Light mode logo */
.logo-light {
  filter: invert(0%) brightness(0%) saturate(100%); /* Black logo */
}

/* Dark mode logo */
.logo-dark {
  filter: invert(100%) brightness(200%) saturate(100%); /* White logo */
}
